




































































































import { Component, Vue } from 'vue-property-decorator';
import {objective_types} from "@/graphql/objective";
import AddTags from "@/components/tags/AddTags.vue";

@Component({
  components: {},
  apollo: {
    objective_types: {
      query: objective_types,
    },
  },
})
export default class BoardActivityFilters extends Vue {
  showOnlyUsedSprints: boolean = false;
  statusSelected: string = 'all';
  ownerSelected: string = 'all';
  prioritySelected: string = 'all';
  objectiveTypeSelected: string = 'all';
  startDateSelected: Date | null = null;
  endDateSelected: Date | null = null;
  objectiveTagSelected: string = 'all';
  dates: Array<any> = [];

  get board() {
    return this.$store.state.board;
  }

  toggleOnlyUsedSprints() {
    this.$events.fire('filter-only-used', true);
  }

  setStatus() {
    this.$events.fire('filter-status', this.statusSelected)
  }
  setPriority() {
    this.$events.fire('filter-priority', this.prioritySelected)
  }

  setObjectiveType() {
    this.$events.fire('filter-objective-type', this.objectiveTypeSelected)
  }

  setOwner() {
    this.$events.fire('filter-owner', this.ownerSelected)
  }

  setBoardTag() {
    this.$events.fire('filter-tag', this.objectiveTagSelected);
  }

  resetFilters() {
    this.showOnlyUsedSprints = false;
    this.statusSelected = 'all';
    this.ownerSelected = 'all';
    this.prioritySelected = 'all';
    this.startDateSelected = null;
    this.endDateSelected = null;
    this.dates = [];
    this.$events.fire('filter-reset', true);
  }

  rangeStart(date: Date){
    this.startDateSelected = date;
  }

  rangeEnd(date: Date){
    this.endDateSelected = date;
    this.$events.fire('filter-date-range',{startDate: this.startDateSelected, endDate: this.endDateSelected});
  }

  addNewTag(){
    this.$buefy.modal.open({
      component: AddTags,
      props: {
        board_id: this.board.id,
        board_tags: this.board.tags,
        level: 'board'
      },
      width: '520px',
      parent: this,
    })
  }
}
