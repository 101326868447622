






































































































































































































import {Component, Mixins} from 'vue-property-decorator';
import Template from "@/components/templates/Template.vue";
import {ValidationErrorParserMixin} from "@/mixins/ValidationErrorParserMixin";
import {AddWorkspaceAttachments} from "@/graphql/workspace";
import {DeleteAttachments, WorkspaceAttachments} from "@/graphql/attachment";
import {AttachmentPaginator, Role} from "@/typescript/types";
import BoardActivityFilters from "@/components/board/widgets/BoardActivityFilters.vue";
import pick from "lodash/pick";
import WorkspacePage from "@/components/layout/WorkspacePage.vue";

@Component({
  components: {BoardActivityFilters, Template, WorkspacePage},
  apollo: {
    workspaceAttachments: {
      fetchPolicy: 'network-only',
      query: WorkspaceAttachments,
      result(result: any) {
        return result.data.workspaceAttachments;
      },
      variables() {
        return {
          id: this.workspace.id,
          boardIds: this.myBoardsIds,
          board_id: this.boardSelected,
          owner_id: this.uploader,
          file_type: this.fileType
        };
      },
    },
  },
})
export default class FilesManager extends Mixins(ValidationErrorParserMixin) {

  showActions: boolean = true;
  filterActive: boolean = false;
  uploadFiles: Array<any> = [];
  filesUploadLoading: boolean = false;
  workspaceAttachments: AttachmentPaginator | null = null;
  filesLoading: boolean = false;
  page: number = 1;
  boardSelected: string = 'all';
  uploader: string = 'all';
  fileType: string = 'all';


  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }


  get workspace(){
    return this.$store.state.workspace;
  }

  get myRole(): Role {
    return this.$store.state.workspace.my_role;
  }

  get myBoards() {
    return this.workspace.boards.filter((board: any) => {
      return board.members.filter((user: any) => {
        return user.id === this.$store.state.me.id;
      })
    })
  }

  get myBoardsIds() {
    let boardIds = new Array();

    this.myBoards.forEach((item: any) => {
      boardIds.push(pick(item, [
        'id',
      ]));
    })

    return boardIds;
  }

  get attachments() {
    let attachmentData: Array<any> = [];
    if(this.workspaceAttachments?.data){
      attachmentData = this.workspaceAttachments.data;
    }
    return attachmentData;
  }

  get hasMoreFiles(){
    return this.workspaceAttachments?.paginatorInfo.hasMorePages;
  }

  loadMore(){
    this.filesLoading = true;
    this.page += 1;
    this.$apollo.queries.workspaceAttachments.fetchMore({
      variables: {
        page: this.page,
        boardIds: this.myBoardsIds,
        board_id: this.boardSelected,
        owner_id: this.uploader,
        file_type: this.fileType
      },
      updateQuery: (previousResult, {fetchMoreResult}) => {
       
        this.filesLoading = false;
        return {
          workspaceAttachments: {
            __typename: previousResult.workspaceAttachments.__typename,
            /**
             * Merging the tag list
             */
            data: [...previousResult.workspaceAttachments.data, ...fetchMoreResult.workspaceAttachments.data],
            paginatorInfo: fetchMoreResult.workspaceAttachments.paginatorInfo,
          },
        };
      },
    });
  }


  get isDisabled(){
    return (this.uploadFiles && this.uploadFiles.length >0) ? false : true;
  }

  deleteUploadFiles(index: any) {
    this.uploadFiles.splice(index, 1);
  }

  getAttachment(fileName: string, folder: string){
    //@ts-ignore
    return this.$attachmentsLinks.file_path[folder]+fileName;
  }

  uploadAttachments(files: any){
    this.filesUploadLoading = true;
    this.$apollo.mutate({
      mutation: AddWorkspaceAttachments,
      variables:{
        workspace_id: this.$store.state.workspace.id,
        files: files
      },
      refetchQueries:[{query: WorkspaceAttachments, variables: {id: this.workspace.id, boardIds: this.myBoardsIds, board_id: this.boardSelected, owner_id: this.uploader, file_type: this.fileType}}]
    }).then((res: any)=>{
      this.filesUploadLoading = false;
      res.data.addWorkspaceAttachments.forEach((value: any)=>{
        this.attachments!.unshift(value);
      });

      this.$buefy.toast.open({
        message: 'Added!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
      this.uploadFiles = [];
    }).catch((errors) => {
      this.filesUploadLoading = false;
      this.parseValidationError(errors);
      for (const [key, value] of Object.entries(this.arrayValidationMessages()!!)) {
        let index = key.split('.');
        this.$buefy.toast.open({
          //@ts-ignore
          message: this.uploadFiles[index[1]].name+' -- '+value,
          position: "is-bottom-right",
          type: "is-danger",
          duration: 5000
        });
      }
      this.uploadFiles = [];
    })
  }

  deleteAttachment(attachment_id: string){
    let index = this.attachments!!.findIndex((attachment: any)=> attachment.id === attachment_id);
    if(index != -1) {
      this.attachments!!.splice(index, 1);
    }
    this.$apollo.mutate({
      mutation: DeleteAttachments,
      variables: {
        attachmentId: attachment_id
      }
    }).then(_ => {
      this.$buefy.toast.open({
        message: 'Deleted!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    })
  }

  async downloadFile(url: string,name: string){
    let blob = await fetch(url).then(r => r.blob());
    var fileURL = window.URL.createObjectURL(blob);
    var fURL = document.createElement('a');

    fURL.href = fileURL;
    fURL.setAttribute('download', name);
    document.body.appendChild(fURL);
    fURL.click();
  }

  dragover(event: any) {
    event.preventDefault();
    /**
     * Add some visual fluff to show the user can drop its files
     */
    if (!event.currentTarget.classList.contains('filemanager-drag-drop')) {
      event.currentTarget.classList.add('currently-dropping');
    }
  }

  dragleave(event: any) {
    /**
     * Clean up
     */
    event.currentTarget.classList.remove('currently-dropping');
  }

  drop(event: any) {
    event.preventDefault();
    this.uploadAttachments(event.dataTransfer.files);
    event.currentTarget.classList.remove('currently-dropping');
  }

  mounted() {

  }

}
